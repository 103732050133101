<template>
    <div>
        <el-table :data="tabledata">
            <el-table-column label="卡号" prop="typeText">
                <template slot-scope="{ row }">
                    <span>{{ row.cardNo?row.cardNo:'-' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="卡片类型" prop="content">
                <template slot-scope="{ row }">
                    <span>{{ row.cardType | initDic(cardTypeDic) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="当前余额" prop="orderId">
                <template slot-scope="{ row }">
                    <span>￥{{ util.numFormat(row.balance) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="单次最大充电时长" prop="operatorUserPhone">
                <template slot-scope="{ row }">
                    <span>{{ row.maxChargingMinutes }}分钟</span>
                </template>
            </el-table-column>
            <el-table-column label="状态" prop="cardNo">
                <template slot-scope="{ row }">
                    <span>{{ row.status ? '暂停使用' : '正常' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width='70'>
                <template slot-scope="{ row }">
                    <el-tooltip class="item" effect="dark" content="解绑" placement="top">
                        <img @click="handlerUnbind(row)" src="../../../assets/icon/userMGT-unbind.png" class="a-wh-16" />
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    import util from '../../../../src/utils/util'
    export default {
        data() {
            return {
                util: util,
                tabledata: [],
                cardTypeDic: []
            }
        },
        props:['userId','phone'],
        watch: {
            userId: {
                immediate: true,
                handler (val) {
                    if(val) {
                        this.getTableData()
                    }
                }
            }
        },
        mounted () {
            this.$getDic('cardType').then(res=>{ this.cardTypeDic = res; })
        },
        methods:{
            getTableData () {
                this.$Axios._get({
                    url: this.$Config.apiUrl.userCardListPC,
                    params: {
                        userId: parseInt(this.userId)
                    }
                }).then((res) => {
                    if (res.result.code == 0) {
                        this.tabledata = res.result.data
                    } else {
                        this.$message.error(res.result.msg)
                    }
                }).catch((error) => {
                    this.$message.error(error)
                })
            },
            handlerUnbind (datas) {
                this.$confirm('解绑卡片后，将会同步解除该卡片与套餐的绑定关系。是否确认解绑该卡？', '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(_ => {
                    this.$Axios._post({
                        url: this.$Config.apiUrl.unbindUserCard,
                        params: {
                            "userCardId": datas.id,
                        }
                    }).then((res) => {
                        if (res.result.code == 0) {
                            this.$message.success('操作成功')
                            this.getTableData()
                        } else {
                            this.$message.error(res.result.message)
                        }
                    }).catch((error) => {
                        this.$message.error(error);
                    })
                }).catch(err => { console.log(err); })
            }
        }
    }
</script>