<template>
    <div class="app-body">
        <div class="a-flex-rsbc">
            <bread-crumb></bread-crumb>
        </div>
        <div class="projectDetail">
            <div class="projectDetail-top a-flex-csbfs ">
                <div class="a-flex-rfsfs">
                    <span class="a-fs-16 a-fw-700">{{ detail.nickName }}</span>
                    <el-tag class="a-ml-08" v-if="detail.isOcr">已实名</el-tag>
                    <el-tag class="a-ml-08" v-else type="info">未实名</el-tag>
                </div>
                <div class="a-mt-16 a-flex-rfsfs a-flex-wrap a-w-100">
                    <div class="projectDetail-top-item">
                        <div>手机号</div>
                        <span>{{ detail.mobile }}</span>
                    </div>
                    <div class="projectDetail-top-item">
                        <div>注册时间</div>
                        <span>{{ detail.createTimeText }}</span>
                    </div>
                    <div class="projectDetail-top-item">
                        <div>上次登录时间</div>
                        <span>{{ detail.lastLoginTimeText }}</span>
                    </div>
                    <div class="projectDetail-top-item">
                        <div>余额(现金/红包/赠送金)</div>
                        <span>&yen;{{ util.numFormat(detail.balance) }} / &yen;{{ util.numFormat(detail.activityBalance) }} / &yen;{{ util.numFormat(detail.giftBalance) }}</span>
                    </div>
                    <div class="projectDetail-top-item" @click="showLockOrder">
                        <div>冻结余额(现金/红包/赠送金)</div>
                        <span>&yen;{{ util.numFormat(detail.lockBalacne) }} / &yen;{{ util.numFormat(detail.lockActivity) }} / &yen;{{ util.numFormat(detail.lockGift) }}</span>
                        <el-tooltip effect="dark" content="关联订单" placement="top">
                            <img src="../../assets/icon/option-detail.png" class="a-wh-16 a-mlr-12 a-mt-5" />
                        </el-tooltip>
                    </div>
                    <div class="projectDetail-top-item" style="align-items: flex-start" @click="showStationMd">
                        <div>毛豆余额(通用/专属)</div>
                        <div class="a-flex-rfsc">
                            <jd-md-price :number="Number(detail.globalPoints || 0) + Number(detail.stationPoints || 0)"></jd-md-price>
                            <span>(</span>
                            <jd-md-price :number="Number(detail.globalPoints || 0)"></jd-md-price>
                            <span>/</span>
                            <jd-md-price :number="Number(detail.stationPoints || 0)"></jd-md-price>
                            <span>)</span>
                        </div>
                        <el-tooltip effect="dark" content="查看站点专属毛豆" placement="top">
                            <img src="../../assets/icon/option-detail.png" class="a-wh-16 a-mlr-12 a-mt-5" />
                        </el-tooltip>
                    </div>

                    <div class="projectDetail-top-item">
                        <div>用户储值总金额</div>
                        <span>&yen;{{ util.numFormat(userStatInfo.userRechargeAmount) }}</span>
                    </div>
                    <div class="projectDetail-top-item">
                        <div>用户储值卡总金额</div>
                        <span>&yen;{{ util.numFormat(userStatInfo.userRechargeMonthCardAmount) }}</span>
                    </div>
                    <div class="projectDetail-top-item">
                        <div>用户总提现金额</div>
                        <span>&yen;{{ util.numFormat(userStatInfo.withDrawAmount) }}</span>
                    </div>
                    <div class="projectDetail-top-item">
                        <div>用户套餐购买总金额</div>
                        <span>&yen;{{ util.numFormat(userStatInfo.monthCardAmount) }} (退款：&yen;{{ util.numFormat(userStatInfo.monthCardRefundAmount) }})</span>
                    </div>
                    <div class="projectDetail-top-item">
                        <div>用户订单总金额</div>
                        <span>&yen;{{ util.numFormat(userStatInfo.orderAmount) }} (退款：&yen;{{ util.numFormat(userStatInfo.orderRefundAmount) }})</span>
                    </div>
                    <div class="projectDetail-top-item">
                        <div>用户充电现金支付总金额</div>
                        <span>&yen;{{ util.numFormat(userStatInfo.chargeCashAmount) }} (退款：&yen;{{ util.numFormat(userStatInfo.chargeCashRefundAmount) }})</span>
                    </div>
                    <div class="projectDetail-top-item">
                        <div>用户余额支付总金额</div>
                        <span>&yen;{{ util.numFormat(userStatInfo.userBalanceAmount) }} (退款：&yen;{{ util.numFormat(userStatInfo.userBalanceRefundAmount) }})</span>
                    </div>
                    <div class="projectDetail-top-item">
                        <div>用户毛豆支付总金额</div>
                        <div class="a-flex-rfsfs">
                            <jd-md-price :number="userStatInfo.pointsAmount || 0"></jd-md-price>
                            <span style="white-space: nowrap"> (退款：</span>
                            <jd-md-price :number="userStatInfo.pointsRefundAmount || 0"></jd-md-price>
                            <span>)</span>
                        </div>
                    </div>
                    <div class="projectDetail-top-item">
                        <div>用户红包支付总金额</div>
                        <span>&yen;{{ util.numFormat(userStatInfo.activityAmount) }} (退款：&yen;{{ util.numFormat(userStatInfo.activityRefundAmount) }})</span>
                    </div>
                    <div class="projectDetail-top-item">
                        <div>用户人脸信息</div>
                        <div class="a-flex-rfsc" style="align-items: stretch" v-if="userFaceInfo">
                            <el-image
                                style="width: 100px; height: 100px; background: #f5f5f5; border-radius: 6px;"
                                :src="userFaceInfo.userFaceUrl"
                                :preview-src-list="[userFaceInfo.userFaceUrl]"
                                fit="contain"></el-image>
                        </div>
                        <span v-else>-</span>
                    </div>
                    <div class="projectDetail-top-item a-flex-rfsfs " >
                        <div>用户车辆信息</div>
                        <div class="a-flex-rfsc" style="align-items: stretch" v-if="userBikeInfo">
                            <el-image
                                style="width: 100px; height: 100px; background: #f5f5f5; border-radius: 6px;"
                                :src="userBikeInfo.imageUrl"
                                :preview-src-list="[userBikeInfo.imageUrl]"
                                fit="contain"></el-image>
                            <div class="a-flex-csbfs a-ml-10 a-fs-12">
                                <span>品牌型号：{{ userBikeInfo.brand || '-' }}</span>
                                <span>车牌号：{{ userBikeInfo.carNumber || '-' }}</span>
                                <!-- <span>车辆类型：{{ userBikeInfo. || '-' }}</span> -->
                                <span>车辆类型：{{ userBikeInfo.typeName }}</span>
                                <span>电瓶规格：{{ userBikeInfo.batteryVoltage || '-' }}</span>
                            </div>
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="projectDetail-top-item a-flex-rfsfs " v-if="info.dataPermission == 99">
                        <div>默认勾选安心充</div>
                        <le-switch 
                            class="editDevType" 
                            style="padding: 0 !important;" 
                            label="" 
                            v-model="defaultSelectRestCharge" 
                            @change="restChargeChange"
                            actext="开启" 
                            inactext="关闭"
                            :acValue="1" 
                            :inacValue="0">
                        </le-switch>
                    </div>
                </div>
                <el-tabs class="a-mt-16 devicetabs" v-model="activeName">
                    <el-tab-pane label="充电消费记录" name="0"></el-tab-pane>
                    <el-tab-pane label="套餐购买记录" name="1"></el-tab-pane>
                    <el-tab-pane label="毛豆消费记录" name="2"></el-tab-pane>
                    <el-tab-pane label="充值记录" name="3"></el-tab-pane>
                    <el-tab-pane label="提现记录" name="4"></el-tab-pane>
                    <el-tab-pane label="充电卡管理" name="5"></el-tab-pane>
                    <el-tab-pane label="用户车辆信息" name="6"></el-tab-pane>
                    <el-tab-pane label="用户优惠券" name="7"></el-tab-pane>
                </el-tabs>
            </div>
            <div class="tab-content">
                <cdxf-record v-if="activeName == '0'" :userId="detail.id" :phone="detail.mobile"></cdxf-record>
                <tcgm-record v-if="activeName == '1'" :userId="detail.id" :phone="detail.mobile"></tcgm-record>
                <mdxf-record v-if="activeName == '2'" :userId="detail.id" :phone="detail.mobile"></mdxf-record>
                <cz-record v-if="activeName == '3'" :userId="detail.id" :phone="detail.mobile"></cz-record>
                <tx-record v-if="activeName == '4'" :userId="detail.id" :phone="detail.mobile"></tx-record>
                <cdk-record v-if="activeName == '5'" :userId="detail.id" :phone="detail.mobile"></cdk-record>
                <user-car-info v-if="activeName == '6'" :userId="detail.id" :phone="detail.mobile" :userBikeInfo='userBikeInfo'></user-car-info>
                <coupon-list v-if="activeName == '7'" :userId="detail.id" :phone="detail.mobile"></coupon-list>
            </div>
        </div>
        <lock-order ref="lockOrder" :userId="detail.id" :phone="detail.mobile"></lock-order>
        <station-md ref="stationMd" :userId="detail.id" :phone="detail.mobile"></station-md>
    </div>
</template>
<script>

import {
    mapGetters,
    mapActions,
    mapState
} from 'vuex';
import util from '../../../src/utils/util'
import cdxfRecord from './child/cdxf-record.vue';
import tcgmRecord from './child/tcgm-record.vue';
import mdxfRecord from './child/mdxf-record.vue';
import czRecord from './child/cz-record.vue';
import txRecord from './child/tx-record.vue';
import cdkRecord from './child/cdk-record.vue';
import LockOrder from './child/lock-order.vue';
import StationMd from './child/station-md.vue';
import UserCarInfo from './child/user-car-info.vue'
import CouponList from './child/coupon-list.vue';
export default {
    components: { 
        cdxfRecord,
        tcgmRecord,
        mdxfRecord,
        czRecord,
        txRecord,
        cdkRecord, 
        LockOrder, 
        StationMd,
        UserCarInfo,
        CouponList
    },
    data() {
        return {
            util: util,
            activeName: '0',
            id: '',
            detail: {},
            userStatInfo: '',
            userBikeInfo: '',
            userFaceInfo: '',
            defaultSelectRestCharge: ''
        }
    },
    created() {
        this.id = this.$route.query.id
        // this.getTableData()
        this.getDetail()
        this.getDefaultSelectRestChargeConfig()
        // this.txjlTableData()
        // this.cdkglTableData()

    },
    mounted() {

    },
    computed: {
        ...mapState({
            info: state => state.user.user
        })
    },
    methods: {
        getDetail() {
            this.$Axios._get({
                url: this.$Config.apiUrl.getUserManagerDetail,
                params: {
                    userId: parseInt(this.id)
                }
            }).then((res) => {
                if (res.result.code == 0) {
                    this.detail = res.result.data
                    this.userStatInfo = res.result.data.userStatInfo
                    this.userBikeInfo = res.result.data.userBikeInfo
                    this.userFaceInfo = res.result.data.userFaceInfo
                }
            })
        },
        // 查询用户是否默认勾选安心充
        getDefaultSelectRestChargeConfig () {
            this.$Axios._post({
                url: this.$Config.apiUrl.getAnxinTag,
                params: {
					userId: this.id,
                }
            }).then(({
                data
            }) => {
                if(data) {
                    this.defaultSelectRestCharge = data.tag
                }else {
                    this.defaultSelectRestCharge = 1
                }
            }).catch(err=>{
                this.defaultSelectRestCharge = 1
            })
        },
        // 查看冻结金额关联订单
        showLockOrder () {
            this.$refs['lockOrder'].dialogVisible = true
            this.$refs['lockOrder'].getTableData()
        },
        // 查看站点专属毛豆
        showStationMd () {
            this.$refs['stationMd'].dialogVisible = true
            this.$refs['stationMd'].getTableData()
        },
        //跳转到订单详情
        godingdan(id) {
            this.$router.push({
                path: '/order/order-detail',
                query: {
                    orderId: id
                }
            })
        },
        restChargeChange (e) {
            this.$Axios._post({
                url: this.$Config.apiUrl.setAnxinTag,
                params: {
                    userId: this.id,
					tag: e
                }
            }).then(({
                data
            }) => {
                this.$message.success("操作成功")
            })
        },
        nfcUseBalanceChange (e) {
            this.$Axios._post({
                url: this.$Config.apiUrl.setChargingParams,
                params: {
                    userId: this.id,
					nfcUseBalance: e
                }
            }).then(({
                data
            }) => {
                this.$message.success("操作成功")
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.projectDetail {
    font-size: 14px;
    height: 100%;
    overflow-y: auto;

    >div {
        border-radius: 4px;
    }

    &-box {
        border-radius: 4px;
        background: #fff;
    }

    &-top {
        background: #fff;
        padding: 24px;
        padding-bottom: 64px;
        position: relative;
        padding-bottom: 0;

        &-item {
            display: flex;
            flex-direction: row;
            width: 480px;
            margin-top: 16px;
            font-size: 14px;
            line-height: 22px;

            >div:nth-child(1) {
                width: 126px;
                color: #797979;
            }

            span {
                margin-left: 16px;
            }
        }
    }

    .tab-content{
        background: #fff;
        padding: 24px;
        position: relative;
        margin-top: 11px;
    }

    /deep/ .el-tabs__item {
        // color: #007aff;
        font-size: 14px;
    }

    /deep/ .el-tabs__item.is-active {
        color: #007AFF;
        font-weight: bold;
    }

    /deep/ .el-tabs__nav-wrap::after {
        background: none !important;
    }

    /deep/ .el-tabs__header {
        padding: 15px 0 0;
        background: #fff;
    }

    .zdxx {
        background: #fff;
        padding: 24px;

        &-table {
            // margin-top: -24px;
            background: #fff;
            width: 100%;
        }
    }
}



/deep/ .el-date-editor--daterange {
    margin: 0;
    margin-top: -12px;
    margin-bottom: -18px;
}

.zdxx-table {
    padding: 24px;
    background: #fff;
}

/deep/ .projectDetail-top-tabs {
    margin-top: 30px;
}

/deep/ .el-table thead {
    color: #606366 !important;
}

/deep/ .el-table {
    color: #303133
}
</style>