<template>
    <div>
        <le-pagview ref="eventlist" @setData="setTableData" :pageParam="pageParam" v-if="pageParam">
            <el-table :data="tabledata">
                <el-table-column label="订单号" prop="typeText">
                    <template slot-scope="{ row }">
                        <span class="font-point a-c-blue" @click="toDetails(row)">{{ row.orderSn?row.orderSn:'-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="订单金额" prop="content">
                    <template slot-scope="{ row }">
                        <span>￥{{ util.numFormat(row.orderPrice) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="套餐名称" prop="orderId">
                    <template slot-scope="{ row }">
                        <span>{{ row.goodsName?row.goodsName:'-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="套餐类型" prop="operatorUserPhone">
                    <template slot-scope="{ row }">
                        <span>{{ row.goodsInfo.isTempCard | initDic(isTempcardDic) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="支付渠道" prop="cardNo">
                    <template slot-scope="{ row }">
                        <span>{{ row.payChannel | initDic(payChannelDic) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="套餐有效日开始时间" prop="userMonthcardStart">
                    <template slot-scope="{ row }">
                        <span>{{ row.userMonthcardStart?row.userMonthcardStart:'-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="套餐有效日结束时间" prop="userMonthcardEnd">
                    <template slot-scope="{ row }">
                        <span>{{ row.userMonthcardEnd?row.userMonthcardEnd:'-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="订单购买时间" prop="createTimeText">
                    <template slot-scope="{ row }">
                        <span>{{ row.createTimeText?row.createTimeText:'-' }}</span>
                    </template>
                </el-table-column>
            </el-table>
        </le-pagview>
    </div>
</template>

<script>
    import util from '../../../../src/utils/util'
    export default {
        data() {
            return {
                util: util,
                pageParam: {
                    url: this.$Config.apiUrl.superOrderPage,
                    method: "post",
                    params: {
                        companyId: '',
                        phone: this.phone,
                        nickName: '',
                        orderSn: '',
                        goodsId: '',
                        goodsName: '',
                        stationId: '',
                        stationName: '',
                        startTimeStr: '',
                        endTimeStr: '',
                        orderStatus: [],
                        payStatus: '',
                        goodsType: '',
                        payChannel: '',
                    },
                    freshCtrl: 1,
                },
                tabledata: [],
                payChannelDic: [],
                isTempcardDic: [],//是否临时卡字典
                payWayDic: []
            }
        },
        props:['userId','phone'],
        mounted () {
            this.$getDic('orderPayChannel').then(res=>{ this.payChannelDic = res; })
            this.$getDic('istempcard').then(res=>{ this.isTempcardDic = res; })
            this.$getDic('payWay').then(res=>{ this.payWayDic = res; })
        },
        methods:{
            setTableData (datas) {
                this.tabledata = datas.map(item=>{
                    return {
                        ...item,
                        goodsInfo: JSON.parse(item.goodsInfo)
                    }
                })
            },
            toDetails (datas) {
                this.$router.push({
                    path:'/order/package-order-info',
                    query: {
                        id: datas?datas.id:''
                    }
                })
            }
        }
    }
</script>