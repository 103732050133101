<template>
    <div>
        <el-dialog
            title="站点专属毛豆"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="800px">
            <div class="formContent">
                <le-pagview ref="eventlist" @setData="setTableData" :pageParam="pageParam" v-if="pageParam">
                    <el-table :data="tabledata" :height='tabledata.length?"60vh":""'>
                        <el-table-column label="站点名称" prop="typeText">
                            <template slot-scope="{ row }">
                                <span>{{ row.stationName || '-' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="可用毛豆数量" prop="content">
                            <template slot-scope="{ row }">
                                <jd-md-price :number="Number(row.buyAmount || 0) + Number(row.giftAmount || 0)"></jd-md-price>
                            </template>
                        </el-table-column>
                    </el-table>
                </le-pagview>
            </div>
            <div slot="footer">
                <el-button type="primary"  @click="dialogVisible = false">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import util from '../../../../src/utils/util'
    export default {
        data() {
            return {
                dialogVisible: false,
                util: util,
                pageParam: null,
                tabledata: [],
            };
        },
        props:['userId','phone'],
        methods:{
            getTableData () {
                this.pageParam = {
                    url: this.$Config.apiUrl.pointsStationDetail,
                    method: "post",
                    params: {
                        userId: this.userId,
                        phone: this.phone,
                    },
                    freshCtrl: 1,
                }
            },
            setTableData (datas) {
                this.tabledata = datas.map(item=>{
                    return {
                        ...item,
                    }
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
    /deep/ .el-dialog__wrapper{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }
    /deep/ .el-dialog__body{
        padding: 10px 20px !important;
    }
    /deep/ .el-dialog{
        margin: 0 auto !important;
    }
    /deep/ .is-disabled{
        color: #333333 !important;
    }
    .formContent{
        max-height: 70vh;
        overflow: auto;
    }
    .tips{
        font-size: 12px;
        color: #909399;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        line-height: 22px;
    }
</style>