<template>
    <div class="a-flex-rsbfs">
        <el-form :model="form" :rules='rules' ref="form" label-width="145px" label-position="left" v-if="!noContent">
            <el-form-item label="车辆类型" prop="type" >
                <span v-if="!isEdit">{{ form.type | initDic(typeDic) }}</span>
                <el-select v-else v-model="form.type" placeholder="请选择车辆类型" @change='bikeTypeChange'>
                    <el-option v-for="(item,index) in typeDic"
                        :key="index"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="品牌" prop="brand" >
                <span v-if="!isEdit">{{ form.brand || '-' }}</span>
                <el-input v-else style="max-width:240px" maxlength="20" v-model="form.brand"></el-input>
            </el-form-item>
            <el-form-item label="电瓶规格" prop="batteryVoltage" >
                <span v-if="!isEdit">{{ form.batteryVoltage | initDic(batteryVoltageDic) }}</span>
                <el-select v-else v-model="form.batteryVoltage" placeholder="请选择电瓶规格">
                    <el-option v-for="(item,index) in batteryVoltageDic"
                        :key="index"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="车牌" prop="carNumber" >
                <span v-if="!isEdit">{{ form.carNumber || '-' }}</span>
                <el-input v-else style="max-width:240px" maxlength="20" v-model="form.carNumber"></el-input>
            </el-form-item>
            <el-form-item label="车辆图片" prop="imagePath" >
                <div class="a-flex-cfsfs a-flex-wrap" v-if="!isEdit">
                    <el-image
                        style="width: 100px; height: 100px; border-radius: 8rpx; margin-right: 10px"
                        :preview-src-list='[form.imagePath]'
                        :src="form.imagePath"></el-image>
                </div>
                <div v-else>
                    <le-upload-file-img ref="pic" v-model="form.imagePath" :limit='1'></le-upload-file-img>
                </div>
            </el-form-item>
            <el-form-item label="充电器图片" prop="imagePaths" >
                <div class="a-flex-cfsfs a-flex-wrap" v-if="!isEdit">
                    <el-image
                        style="width: 100px; height: 100px; border-radius: 8rpx; margin-right: 10px"
                        :preview-src-list='[form.imagePaths]'
                        :src="form.imagePaths"></el-image>
                </div>
                <div v-else>
                    <le-upload-file-img ref="pic" v-model="form.imagePaths" :limit='1'></le-upload-file-img>
                </div>
            </el-form-item>
        </el-form>
        <div v-else class="a-w-100 a-flex-rcc">
            <el-empty description="暂无车辆信息"></el-empty>
        </div>
        <div class="a-flex-rfsc">
            <el-button type="primary" plain @click="toChangeRecord">查看变更记录</el-button>
            <el-button type="primary" @click="handleEdit">{{ isEdit ? '提交' : '修改' }}</el-button>
            <el-button type="danger" @click="handleCancel" v-if="isEdit">取消</el-button>
        </div>
    </div>
</template>

<script>
    import util from '../../../../src/utils/util'
    export default {
        data() {
            return {
                util: util,
                isEdit: false,//是否正在修改信息
                form: {
                    type: '',
                    brand: '',
                    batteryVoltage: '',
                    carNumber: '',
                    imagePath: '',
                    imagePaths: ''
                },
                originDatas: '',
                rules: {
                    type: [{required: true, message:'请选择车辆类型', trigger: 'change'}],
                    brand: [{required: true, message:'请输入品牌', trigger: 'blur'}],
                    batteryVoltage: [{required: true, message:'请选择车辆电瓶规格', trigger: 'change'}],
                    // carNumber: [{required: true, message:'请输入车牌号', trigger: 'blur'}],
                    imagePath: [{required: true, message:'请上传车辆图片', trigger: 'change'}],
                    imagePaths: [{required: true, message:'请上传充电器图片', trigger: 'change'}]
                },
                typeDic: [],//车辆类型字典
                batteryVoltageDic: [],//车辆电瓶规格字典
                noContent: false
            }
        },
        props:['userId','phone'],
        created () {
            this.$getDic('userBikeType','select').then(res=>{ this.typeDic = res; })
            this.$getDic('userBikeBattery','select').then(res=>{ this.batteryVoltageDic = res; })
        },
        mounted () {
            this.getTableData()
        },
        methods:{
            getTableData () {
                this.$Axios._post({
                    url: this.$Config.apiUrl.getUserBikeDetail,
                    params: {
                        userId: parseInt(this.userId)
                    }
                }).then((res) => {
                    if(res.result.data) {
                        this.form = {
                            ...res.result.data,
                        }
                        this.originDatas = JSON.stringify(res.result.data)
                        this.getBikeBatteryDic()
                    }else {
                        this.noContent = true
                    }
                })
            },
            bikeTypeChange () {
                this.form.batteryVoltage = ''
                this.getBikeBatteryDic()
            },
            getBikeBatteryDic () {
                this.$Axios._post({
                    url: this.$Config.apiUrl.batterybList,
                    showLoading: false,
                    params: {
                        bikeId: this.form.type
                    }
                }).then((res) => {
                    if (res.result.code == 0) {
                        this.batteryVoltageDic = res.result.data.map(item=>{
                            return {
                                ...item,
                                label: item.batteryVoltage,
                                value: JSON.stringify(item.id)
                            }
                        })
                    }
                })
            },
            toChangeRecord () {
                this.$router.push({
                    path: '/userMGT/userMGT-carchange-list',
                    query: {
                        userId: this.userId
                    }
                })
            },
            handleEdit () {
                if(this.isEdit) {
                    this.$refs['form'].validate((valid) => {
                        if (valid) {
                            this.$confirm('是否确认取消修改？','温馨提示')
                                .then(_=>{
                                    this.$Axios._post({
                                        url: this.$Config.apiUrl.backUserChangeBike,
                                        showLoading: false,
                                        params: {
                                            ...this.form
                                        }
                                    }).then((res) => {
                                        if (res.result.code == 0) {
                                            this.$message.success('操作成功')
                                            this.isEdit = false
                                        }
                                    })
                                    
                                })
                                .catch(_=>{

                                })
                        } else {
                            return false
                        }
                    })
                    
                }else {
                    this.isEdit = true
                    this.noContent = false
                }
            },
            handleCancel () {
                this.$confirm('是否确认取消修改？','温馨提示',{
                    confirmButtonText: '是',
                    cancelButtonText: '否'
                })
                .then(_=>{
                    this.isEdit = false
                    if(!this.originDatas) {
                        this.noContent = true
                    }
                    this.$refs['form'].clearValidate()
                    this.form = JSON.parse(this.originDatas)
                })
                .catch(_=>{
                    
                })
            }
        }
    }
</script>