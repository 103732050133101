<template>
    <div>
        <le-pagview ref="eventlist" @setData="setTableData" :pageParam="pageParam" v-if="pageParam">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="false" :showReset='false' :showSearch='false'>
                <le-select-local-search label="充值类型" v-model="recordType" :options="optionsOrderType" style="padding: 0 0 12px !important" />
            </le-search-form>
            <el-table :data="tabledata">
                <el-table-column label="充值金额" prop="typeText">
                    <template slot-scope="{ row }">
                        <span v-if="recordType == 1">&yen;{{ util.numFormat(row.goodsPrice) }}</span>
                        <span v-else>&yen;{{ util.numFormat(row.changeAmount) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="获得毛豆" prop="content" v-if="recordType == 1">
                    <template slot-scope="{ row }">
                        <jd-md-price :number="row.changeAmount|| 0"></jd-md-price>
                    </template>
                </el-table-column>
                <el-table-column :label="recordType == 1?'充值后毛豆余额':'充值后余额'" prop="content">
                    <template slot-scope="{ row }">
                        <jd-md-price v-if="recordType == 1" :number="row.afterBalance || 0"></jd-md-price>
                        <span v-else>&yen;{{ util.numFormat(row.afterBalance) }}</span>
                    </template>
                </el-table-column>
                
                <el-table-column label="储值卡套餐" prop="content" v-if="recordType == 1">
                    <template slot-scope="{ row }">
                        <span>{{ row.goodsName || '-' }}</span>
                    </template>
                </el-table-column>
                
                <el-table-column label="充值时间" prop="orderId">
                    <template slot-scope="{ row }">
                        <span>{{ row.recordTime || row.createTimeText || '-' }}</span>
                    </template>
                </el-table-column>
            </el-table>
        </le-pagview>
    </div>
</template>

<script>
    import util from '../../../../src/utils/util'
    export default {
        data() {
            return {
                util: util,
                recordType: '1',//记录类型
                pageParam: null,
                tabledata: [],
                optionsOrderType: [{
                    label: '储值套餐卡',
                    value: '1'
                },{
                    label: '普通充值',
                    value: '2'
                },{
                    label: '实体卡充值',
                    value: '3'
                }],
            }
        },
        props:['userId','phone'],
        mounted () {
            
        },
        watch: {
            recordType: {
                handler (val) {
                    this.handlerSearch()
                }
            },
            phone: {
                immediate: true,
                handler (val) {
                    if(val) {
                        this.changeRecordType()
                    }
                }
            }
        },
        methods:{
            handlerRest() {
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs.eventlist.pageNum = 1
                this.changeRecordType()
            },
            setTableData (datas) {
                this.tabledata = datas
            },
            changeRecordType () {
                this.pageParam = null
                this.tabledata = []
                if(this.recordType == '1') {
                    this.$nextTick(()=>{
                        this.$set(this,'pageParam',{
                            url: this.$Config.apiUrl.userMdRechargeRecord,
                            method: "post",
                            params: {
                                userId: this.userId,
                            },
                            freshCtrl: 1,
                        })
                    })
                }else if(this.recordType == '2') {
                    this.$nextTick(()=>{
                        this.$set(this,'pageParam',{
                            url: this.$Config.apiUrl.getUserChargeBalanceInfoList,
                            method: "post",
                            params: {
                                userId: this.userId,
                            },
                            freshCtrl: 1,
                        })
                    })
                    
                }else if(this.recordType == '3') {
                    this.$nextTick(()=>{
                        this.$set(this,'pageParam',{
                            url: this.$Config.apiUrl.userCardRechargeRecord,
                            method: "post",
                            params: {
                                userId: this.userId,
                            },
                            freshCtrl: 1,
                        })
                    })
                    
                }
            },
        }
    }
</script>