<template>
    <div>
        <el-dialog
            title="适用范围"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="1240px">
            <div class="formContent a-flex-rfsfs">
                <div class="a-flex-1 a-p-10" v-if="companyDatas.length">
                    <span class="a-fw-700 a-pb-10 block">商户：</span>
                    <el-table
                        ref="goodsPagelist"
                        :data="companyDatas" 
                        :border="true"
                        style="width:100%;height:100%">
                        <el-table-column label="ID" prop="typeText" >
                            <template slot-scope="{ row }">
                                <span>{{ row.scopeId?row.scopeId:'-' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="名称" prop="typeText" >
                            <template slot-scope="{ row }">
                                <span>{{ row.name?row.name:'-' }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="a-flex-1 a-p-10" v-if="incomeDatas.length">
                    <span class="a-fw-700 a-pb-10 block">项目：</span>
                    <el-table
                        ref="goodsPagelist"
                        :data="incomeDatas" 
                        :border="true"
                        style="width:100%;height:100%">
                        <el-table-column label="ID" prop="typeText" >
                            <template slot-scope="{ row }">
                                <span>{{ row.scopeId?row.scopeId:'-' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="名称" prop="typeText" >
                            <template slot-scope="{ row }">
                                <span>{{ row.name?row.name:'-' }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="a-flex-1 a-p-10" v-if="stationDatas.length">
                    <span class="a-fw-700 a-pb-10 block">站点：</span>
                    <el-table
                        ref="goodsPagelist"
                        :data="stationDatas" 
                        :border="true"
                        style="width:100%;height:100%">
                        <el-table-column label="ID" prop="typeText" >
                            <template slot-scope="{ row }">
                                <span>{{ row.scopeId?row.scopeId:'-' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="名称" prop="typeText" >
                            <template slot-scope="{ row }">
                                <span>{{ row.name?row.name:'-' }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div slot="footer">
                <el-button type="primary"  @click="dialogVisible = false">确定</el-button>
            </div>
        </el-dialog>
        
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dialogVisible: false,
                companyDatas: [],
                incomeDatas: [],
                stationDatas: []
            };
        },
        props: {
            tableData: {
                default: ()=>{
                    return []
                }
            }
        },
        watch: {
            tableData: {
                deep: true,
                immediate: true,
                handler (val) {
                    this.companyDatas = []
                    this.incomeDatas = []
                    this.stationDatas = []
                    if(val && val.length) {
                        val.map(item=>{
                            if(item.scopeType == 1) {
                                this.companyDatas.push(item)
                            }else if(item.scopeType == 2) {
                                this.incomeDatas.push(item)
                            }else if(item.scopeType == 3) {
                                this.stationDatas.push(item)
                            }
                        })
                    }
                }
            }
        },
        methods:{
            
        }
    }
</script>

<style lang="scss" scoped>
    .tableTitle{
        font-size: 14px;
        color: #333333;
        font-weight: 700;
        margin: 24px 0;
        display: block;
    }
    /deep/ .el-dialog__wrapper{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }
    /deep/ .el-dialog__body{
        padding: 10px 20px !important;
    }
    /deep/ .el-dialog{
        margin: 0 auto !important;
    }
    /deep/ .is-disabled{
        color: #333333 !important;
    }
    .formContent{
        max-height: 70vh;
        overflow: auto;
    }
    .tips{
        font-size: 12px;
        color: #909399;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        line-height: 22px;
    }
</style>