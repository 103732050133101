<template>
    <div>
        <el-table :data="tabledata">
            <el-table-column label="提现时间" prop="typeText">
                <template slot-scope="{ row }">
                    <span>{{ row.createTimeText || '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="提现金额" prop="content">
                <template slot-scope="{ row }">
                    <span>￥{{ util.numFormat(row.amount) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="提现状态" prop="orderId">
                <template slot-scope="{ row }">
                    <span>{{ row.isSuccess ? '成功' : '失败' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="提现后余额" prop="operatorUserPhone">
                <template slot-scope="{ row }">
                    <span>￥{{ util.numFormat(row.afterBalance) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="提现人ID" prop="cardNo">
                <template slot-scope="{ row }">
                    <span>{{ row.type ? row.companyId : row.userId }}</span>
                </template>
            </el-table-column>
            <el-table-column label="提现ID" prop="cardNo">
                <template slot-scope="{ row }">
                    <span>{{ row.type ? row.withdrawId : row.balanceId }}</span>
                </template>
            </el-table-column>
            <el-table-column label="APPID" prop="createTimeText">
                <template slot-scope="{ row }">
                    <span>{{ row.mchAppid || '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="错误描述" prop="createTimeText">
                <template slot-scope="{ row }">
                    <span>{{ row.errMessage || '-' }}</span>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    import util from '../../../../src/utils/util'
    export default {
        data() {
            return {
                util: util,
                tabledata: [],
            }
        },
        props:['userId','phone'],
        mounted () {
            this.getTableData()
        },
        methods:{
            getTableData () {
                this.$Axios._get({
                    url: this.$Config.apiUrl.getTransfersList,
                    params: {
                        userId: parseInt(this.userId)
                    }
                }).then((res) => {
                    if (res.result.code == 0) {
                        this.tabledata = res.result.data
                    }
                })
            },
        }
    }
</script>