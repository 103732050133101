<template>
    <div>
        <le-pagview ref="eventlist" @setData="setTableData" :pageParam="pageParam" v-if="pageParam">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="false" :showReset='false' :showSearch='false'>
                <le-select-local-search label="订单类型" v-model="orderType" :options="optionsOrderType" style="padding: 0 0 12px !important" />
            </le-search-form>
            <el-table :data="tabledata">
                <el-table-column label="订单号" prop="typeText">
                    <template slot-scope="{ row }">
                        <span v-if="orderType == 1" class="font-point a-c-blue" @click="toChargeDetails(row)">{{ row.orderId?row.orderId:'-' }}</span>
                        <span v-if="orderType == 2" class="font-point a-c-blue" @click="toCabinetDetails(row)">{{ row.orderSn?row.orderSn:'-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="订单金额" prop="content">
                    <template slot-scope="{ row }">
                        <span v-if="orderType == 1">￥{{ util.numFormat(row.amount) }}</span>
                        <span v-if="orderType == 2">￥{{ util.numFormat(row.orderPrice) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="订单时长" prop="orderId">
                    <template slot-scope="{ row }">
                        <span v-if="orderType == 1">{{ row.realMinutes?row.realMinutes:'0' }}分钟</span>
                        <span v-if="orderType == 2">{{ row.totalMinutes || '0' }}分钟</span>
                    </template>
                </el-table-column>
                <el-table-column label="有功功率(W)" prop="activePower">
                    <template slot-scope="{ row }">
                        <span>{{ row.activePower || '-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="消费情况(现金/红包/毛豆)" prop="operatorUserPhone">
                    <template slot-scope="{ row }">
                        <span>￥{{ util.numFormat(Number(row.payAmount || 0) - Number(row.refundAmount || 0)) }} / </span>
                        <span>￥{{ row.orderDiscountInfoList | initPayInfo(2,100) }}/</span>
                        <img class="md-icon" src="https://hichar-file.oss-cn-shanghai.aliyuncs.com/app/wxapp/md-icon3.0.png" alt="">
                        <span>{{ row.orderDiscountInfoList | initPayInfo(4,1) }}</span>
                    </template>
                </el-table-column>
                <el-table-column :label="orderType == 1?'支付方式':'支付渠道'" prop="cardNo">
                    <template slot-scope="{ row }">
                        <span v-if="orderType == 1">{{ row.payTypeText || '-' }}</span>
                        <span v-if="orderType == 2">{{ row.payChannel | initDic(payChannelDic) }}</span>
                    </template>
                </el-table-column>
                <el-table-column :label="orderType == 1?'订单开始时间':'开柜时间'" prop="createTimeText">
                    <template slot-scope="{ row }">
                        <span v-if="orderType == 1">{{ row.createTimeText?row.createTimeText:'-' }}</span>
                        <span v-if="orderType == 2">{{ row.orderStartTime?row.orderStartTime:'-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column :label="orderType == 1?'订单结束时间':'取电时间'" prop="createTimeText">
                    <template slot-scope="{ row }">
                        <span v-if="orderType == 1">{{ row.endTimeText?row.endTimeText:'-' }}</span>
                        <span v-if="orderType == 2">{{ row.orderEndTime?row.orderEndTime:'-' }}</span>
                    </template>
                </el-table-column>
            </el-table>
        </le-pagview>
    </div>
</template>

<script>
    import util from '../../../../src/utils/util'
    export default {
        data() {
            return {
                util: util,
                orderType: '1',//订单类型
                pageParam: null,
                tabledata: [],
                optionsOrderType: [{
                    label: '充电桩',
                    value: '1'
                },{
                    label: '充电柜/仓',
                    value: '2'
                }],
                payChannelDic: [],
                discountTypeDic: {
                    1: '活动',
                    2: '红包',
                    3: 'VIP',
                    4: '毛豆',
                }
            }
        },
        props:['userId','phone'],
        mounted () {
            this.$getDic('payChannel').then(res=>{ this.payChannelDic = res; })
        },
        watch: {
            orderType: {
                handler (val) {
                    this.handlerSearch()
                }
            },
            phone: {
                immediate: true,
                handler (val) {
                    if(val) {
                        this.changeOrderType()
                    }
                }
            }
        },
        filters: {
            initPayInfo (arr, type, radio) {
                let discount = arr.find(res=>{
                    return res.discountType == type
                })
                if(discount) {
                    let amount = Number(discount.discountPayAmount || 0) - Number(discount.refundAmount || 0)
                    return amount ? Number((amount / radio).toFixed(2)) : 0
                }else{
                    return '0'
                }
            }
        },
        methods:{
            handlerRest() {
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs.eventlist.pageNum = 1
                this.changeOrderType()
            },
            setTableData (datas) {
                this.tabledata = datas.map(item=>{
                    return {
                        ...item,
                    }
                })
            },
            changeOrderType () {
                this.pageParam = null
                this.tabledata = []
                if(this.orderType == '1') {
                    this.$nextTick(()=>{
                        this.$set(this,'pageParam',{
                            url: this.$Config.apiUrl.getOrderList,
                            method: "post",
                            params: {
                                mobile: this.phone,
                                deviceCode: "",
                                startTime: "",
                                endTime: "",
                                orderId: "",
                                stationName: "",
                                status: [],
                                payChannel: "",
                                continueOrder: ""
                            },
                            freshCtrl: 1,
                        })
                    })
                }else {
                    this.$nextTick(()=>{
                        this.$set(this,'pageParam',{
                            url: this.$Config.apiUrl.cabinetOrderPage,
                            method: "post",
                            params: {
                                companyId: '',
                                phone: this.phone,
                                nickName: '',
                                orderSn: '',
                                goodsId: '',
                                goodsName: '',
                                stationId: '',
                                stationName: '',
                                startTime: '',
                                endTime: '',
                                orderStatus: [],
                                payStatus: '',
                                orderGoodsType: '',
                                payChannel: '',
                                deviceCode: '',
                                orderType: ''
                            },
                            freshCtrl: 1,
                        })
                    })
                    
                }
            },
            toChargeDetails (datas) {
                this.$router.push({
                    path: '/order/order-detail',
                    query: {
                        orderId: datas.orderId,
                    }
                })
            },
            toCabinetDetails (datas) {
                this.$router.push({
                    path:'/order/chargeCabinet-order-info',
                    query: {
                        orderId: datas.id,
                        orderSn: datas.orderSn
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .md-icon{
        // transform: rotateY(180deg);
        width:20px;
        height:21px;
        margin: 0 8px
    }
</style>