<template>
    <div>
        <le-pagview ref="eventlist" @setData="setTableData" :pageParam="pageParam" v-if="pageParam">
            <el-table :data="tabledata">
                <el-table-column label="订单号" prop="typeText">
                    <template slot-scope="{ row }">
                        <span class="font-point a-c-blue" @click="toDetails(row)">{{ row.orderSn?row.orderSn:'-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="毛豆消耗数" prop="content">
                    <template slot-scope="{ row }">
                        <jd-md-price :number="row.changeAmount || 0"></jd-md-price>
                    </template>
                </el-table-column>
                <el-table-column label="消耗后毛豆剩余数" prop="orderId">
                    <template slot-scope="{ row }">
                        <jd-md-price :number="row.afterAmount || 0"></jd-md-price>
                    </template>
                </el-table-column>
                <el-table-column label="订单类型" prop="operatorUserPhone">
                    <template slot-scope="{ row }">
                        <span>{{ row.orderType | initDic(orderTypeDic) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="订单时间" prop="cardNo">
                    <template slot-scope="{ row }">
                        <span>{{ row.recordTime || '-' }}</span>
                    </template>
                </el-table-column>
            </el-table>
        </le-pagview>
    </div>
</template>

<script>
    import util from '../../../../src/utils/util'
    export default {
        data() {
            return {
                util: util,
                pageParam: {
                    url: this.$Config.apiUrl.pointsConsumeRecord,
                    method: "post",
                    params: {
                        userId: this.userId,
                    },
                    freshCtrl: 1,
                },
                tabledata: [],
                orderTypeDic: []
            }
        },
        props:['userId','phone'],
        mounted () {
            this.$getDic('orderType').then(res=>{ this.orderTypeDic = res; })
        },
        methods:{
            setTableData (datas) {
                this.tabledata = datas.map(item=>{
                    return {
                        ...item,
                    }
                })
            },
            toDetails (datas) {
                if(datas.orderType == 1) {
                    this.$router.push({
                        path:'/order/package-order-info',
                        query: {
                            id: datas?datas.orderSn:''
                        }
                    })
                }else if(datas.orderType == 2) {
                    this.$router.push({
                        path: '/order/order-detail',
                        query: {
                            orderId: datas.orderSn,
                        }
                    })
                }else if(datas.orderType == 4 || datas.orderType == 5) {
                    this.$router.push({
                        path:'/order/chargeCabinet-order-info',
                        query: {
                            orderSn: datas.orderSn
                        }
                    })
                }
                
            }
        }
    }
</script>