<template>
    <div>
        <le-pagview ref="eventlist" @setData="setTableData" :pageParam="pageParam">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="false" :showReset='true' :showSearch='true'>
                <le-input label="优惠券ID" v-model="pageParam.params.couponId" />
                <le-input label="用户优惠券ID" v-model="pageParam.params.userCouponId" />
                <le-select-local-search label="状态" v-model="pageParam.params.status" :options="statusDic" />
            </le-search-form>
            <el-table :data="tabledata">
                <el-table-column label="优惠券ID" prop="typeText">
                    <template slot-scope="{ row }">
                        <span>{{ row.couponId?row.couponId:'-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="优惠券" prop="content">
                    <template slot-scope="{ row }">
                        <span>{{ row.couponName?row.couponName:'-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="用户优惠券ID" prop="orderId">
                    <template slot-scope="{ row }">
                        <span>{{ row.userCouponId?row.userCouponId:'-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="可用范围" prop="activePower">
                    <template slot-scope="{ row }">
                        <span class="font-point a-c-blue" @click="handleShowScope(row)">查看</span>
                    </template>
                </el-table-column>
                <el-table-column label="用户手机号" prop="operatorUserPhone">
                    <template slot-scope="{ row }">
                        <span>{{ row.mobile?row.mobile:'-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="来源" prop="cardNo">
                    <template slot-scope="{ row }">
                        <span v-if="row.fromPlatform == 1">抽奖</span>
                        <span v-else-if="row.fromPlatform == 2">活动</span>
                        <span v-else-if="row.fromPlatform == 3">购买</span>
                    </template>
                </el-table-column>
                <el-table-column label="关联活动" prop="createTimeText">
                    <template slot-scope="{ row }">
                        <span class="a-c-blue font-point" @click="handleToActivity(row)">{{ row.activityName?row.activityName:'-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="关联活动ID" prop="createTimeText">
                    <template slot-scope="{ row }">
                        <span>{{ row.activityId?row.activityId:'-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="状态" min-width="120">
                    <template slot-scope="{ row }">
                        <span>{{ row.status | initDic(statusDic) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="可用时间" min-width="120">
                    <template slot-scope="{ row }">
                        <span>{{ row.startTime }}至{{ row.endTime }}</span>
                    </template>
                </el-table-column>
                <!-- <el-table-column label="优惠券类型" min-width="120">
                    <template slot-scope="{ row }">
                        <span>{{ row.couponType | initDic(couponTypeDic) }}</span>
                    </template>
                </el-table-column> -->
            </el-table>
        </le-pagview>
        <coupon-scope-list :tableData='scopeTableData' ref="couponScopeList"></coupon-scope-list>
    </div>
</template>

<script>
    import util from '../../../../src/utils/util'
    import couponScopeList from './coupon-scope-list.vue'
    export default {
        components: {
            couponScopeList
        },
        data() {
            return {
                util: util,
                pageParam: {
                    url: this.$Config.apiUrl.userCouponList,
                    method: "post",
                    params: {
                        userId: this.userId,
                        couponId: "",
                        status: "",
                        userCouponId: "",
                    },
                    freshCtrl: 1,
                },
                tabledata: [],
                statusDic: [],
                couponTypeDic: [],
                scopeTableData: []
            }
        },
        props:['userId','phone'],
        mounted () {
            this.$getDic('jdCouponStatus','select').then(res=>{ this.statusDic = res; })
            // this.$getDic('couponType').then(res=>{ this.couponTypeDic = res; })
        },
        methods:{
            handlerRest() {
                this.pageParam.params = {
                    userId: this.userId,
                    couponId: "",
                    status: "",
                    userCouponId: "",
                }
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs.eventlist.pageNum = 1
                this.pageParam.freshCtrl++
            },
            setTableData (datas) {
                this.tabledata = datas.map(item=>{
                    return {
                        ...item,
                    }
                })
            },
            handleShowScope (datas) {
                this.scopeTableData = datas.scopeValidList
                this.$refs['couponScopeList'].dialogVisible = true
            },
            handleToActivity (datas) {
                this.$router.push({
                    path: '/activity/activity-edit',
                    query:{
                        id: datas&&datas.activityId?datas.activityId:''
                    }
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
    /deep/ .s-search-label {
        width: 110px !important;
    }
</style>